import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Westhoven: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Westhoven,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Westhoven"
          d="M700,744l20,24l-19,53l-1.6,0.1c-10.2,0.6-20.5,0.1-30.6-1.4l-5.9-0.9c-3.3-0.5-6.4-1.3-9.5-2.4h0 c-11.5-4.2-22.3-10.1-32-17.6l0,0c0.6-1,61.8-55.8,62.6-56.7L700,744z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 641.2063 799.6602)"
        >
          Westhoven
        </text>
      </g>
    </>
  );
});
